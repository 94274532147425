
<template>
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="close" clip-path="url(#clip0_949_5078)">
  <path id="Vector" d="M15.8337 5.34167L14.6587 4.16667L10.0003 8.82501L5.34199 4.16667L4.16699 5.34167L8.82533 10L4.16699 14.6583L5.34199 15.8333L10.0003 11.175L14.6587 15.8333L15.8337 14.6583L11.1753 10L15.8337 5.34167Z" fill="white" fill-opacity="0.87"/>
  </g>
  <defs>
  <clipPath id="clip0_949_5078">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
    